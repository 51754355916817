<template>
  <div class="ticket-create-container">
    <a-breadcrumb>
      <a-breadcrumb-item><a href="/#/ticket">{{ $t('gong-dan') }}</a></a-breadcrumb-item>
      <a-breadcrumb-item>{{ $t('ti-jiao-gong-dan') }}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-button class="refresh-btn" type="default">{{ $t('shua-xin') }}</a-button>
    <div class="create-content-container" :style="`height:${contentHeight}px`">
      <div class="create-ticket-editor">
        <div class="create-ticket-editor-operator">
          <DsSelect :ticketData="ticketData" :handleChangeInstance="handleChangeInstance"
                    :handleChangeSchema="handleChangeSchema"
                    :allDsList="allDsList" :handleChangeDb="handleChangeDb" :selectedDs="selectedDs"></DsSelect>
        </div>
        <MyEditor :style="`height:${codemirrorHeight}px`" :tab="ticketData" :key="ticketData.instanceId"
                  :updateMyMirror="updateMyMirror" type="ticket"></MyEditor>
      </div>
      <div class="create-ticket-content">
        <a-form-model label-position="top" :model="ticketData" :rules="ticketRuleValidate">
          <a-form-model-item :label="$t('biao-ti')" prop="ticketTitle">
            <a-input v-model="ticketData.ticketTitle"/>
          </a-form-model-item>
          <a-form-model-item :label="$t('xu-qiu-miao-shu')" prop="description">
            <a-input type="textarea" v-model="ticketData.description" :rows="4"/>
          </a-form-model-item>
          <a-form-model-item :label="$t('shen-pi-liu-mo-ban')" prop="approvalType">
            <a-select v-model="ticketData.templateIdentity" filterable>
              <a-select-option v-for="template in templateList"
                               :value="template.templateIdentity+'|'+template.templateName+'|'+template.approvalType"
                               :key="template.templateIdentity">
                {{ template.templateName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item :label="$t('shi-li-ming-ku-ming')" prop="instanceId">
            <a-input disabled :value="getDsName"/>
          </a-form-model-item>
        </a-form-model>
        <div class="create-ticket-form-btn">
          <a-button type="primary" :loading="loading" @click="handleSubmitTicket">{{ $t('ti-jiao') }}</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">
import MyEditor from '@/views/sql/components/MyEditor';
import { Modal } from 'ant-design-vue';
import DsSelect from '@/views/ticket/components/DsSelect';
import { APPROVAL_BIZ_TYPE } from '@/const';
import { hasSchema, noStruct } from '../../utils/index';

export default {
  name: 'Ticket',
  components: {
    MyEditor,
    DsSelect
  },
  data() {
    return {
      checkedSql: '',
      loading: false,
      contentHeight: 0,
      codemirrorHeight: 0,
      myCodeMirror: '',
      currentMethod: '',
      allDsList: [],
      templateList: [],
      selectedDs: {},
      ticketData: {
        instanceId: undefined,
        database: undefined,
        approvalType: 'DINGDING',
        immediately: 'immediately',
        dataSourceType: 'MySQL'
      },
      ticketRuleValidate: {
        ticketTitle: [
          {
            required: true,
            message: this.$t('biao-ti-bu-neng-wei-kong'),
            trigger: 'blur'
          }
        ],
        description: [
          {
            required: true,
            message: this.$t('xu-qiu-miao-shu-bu-neng-wei-kong'),
            trigger: 'blur'
          }
        ],
        approvalType: [
          {
            required: true,
            message: this.$t('shen-pi-mo-ban-bu-neng-wei-kong'),
            trigger: 'blur'
          }
        ],
        instanceId: [
          {
            required: true,
            message: this.$t('ku-he-shi-li-ming-bu-neng-wei-kong'),
            trigger: 'blur'
          }
        ],
        planTime: []
      }
    };
  },
  created() {
    window.$bus.on('ticket_listfirstlevel_callback', this.handlePreRequest);
  },
  mounted() {
    this.contentHeight = window.innerHeight - 160;
    this.codemirrorHeight = window.innerHeight - 160 - 60;
    this.getAllDs();
    this.ticketData.ticketTitle = `${this.$t('gong-dan')}${new Date().getTime()}`;
    if (localStorage.getItem('sqlToTicket')) {
      this.ticketData.queryText = localStorage.getItem('sqlToTicket');
      localStorage.removeItem('sqlToTicket');
    }
  },
  computed: {
    getDsName() {
      const selectedDs = this.selectedDs;
      const ticketData = this.ticketData;
      let value = '';
      if (selectedDs.id) {
        value += selectedDs.instanceDesc ? selectedDs.instanceDesc : selectedDs.instanceId;
        if (ticketData.database) {
          value += `/${ticketData.database}`;
          if (ticketData.schema) {
            value += `/${ticketData.schema}`;
          }
        }
      }
      return value;
    }
  },
  methods: {
    handlePreRequest() {
      if (this.currentMethod === 'listFirstLevel') {
        this.listSchemaFirstLevel();
      }
    },
    async listAllDs() {
      const res = await this.$services.getDsList({
        data: {
          lifeCycleState: 'CREATED',
          useVisibility: true
        }
      });
      if (res.success) {
        this.allDsList = res.data;
        if (localStorage.getItem('instanceToTicket')) {
          this.ticketData.dataSourceType = localStorage.getItem('dsTypeToTicket');
          this.ticketData.instanceId = parseInt(localStorage.getItem('instanceToTicket'), 10);
          this.listSchemaFirstLevel();
          this.getDsTemplates();
          this.allDsList.map((ds) => {
            if (ds.id.toString() === localStorage.getItem('instanceToTicket')) {
              this.selectedDs = ds;
            }
            return null;
          });
          if (localStorage.getItem('databaseToTicket')) {
            this.ticketData.database = localStorage.getItem('databaseToTicket');
            if (hasSchema(this.selectedDs.dataSourceType)) {
              this.listDsSchema();
            } else {
              this.listDbTables();
            }
          }
          if (localStorage.getItem('schemaToTicket')) {
            this.ticketData.schema = localStorage.getItem('schemaToTicket');
            this.listDbTables();
          }
          localStorage.removeItem('instanceToTicket');
          localStorage.removeItem('databaseToTicket');
          localStorage.removeItem('schemaToTicket');
          localStorage.removeItem('dsTypeToTicket');
        }
      }
    },
    async getDsTemplates() {
      const res = await this.$services.listDsApproTemplateByDsId({ data: { dataSourceId: this.ticketData.instanceId } });

      if (res.success) {
        this.templateList = res.data.filter((ticket) => {
          console.log(ticket);
          return ticket.approvalBiz === APPROVAL_BIZ_TYPE.EXECUTE;
        });
      }
    },
    getAllDs() {
      this.listAllDs();
    },
    updateMyMirror(editor) {
      this.myCodeMirror = editor;
    },
    async createTicket() {
      this.loading = true;
      const data = {
        dataSourceId: this.ticketData.instanceId,
        // dataSourceType: 'MySQL',
        currentSchema: this.ticketData.schema ? this.ticketData.schema : this.ticketData.database,
        currentDb: this.ticketData.schema ? this.ticketData.database : '',
        targetInfo: noStruct(this.selectedDs.dataSourceType) ? '/' : `/${this.ticketData.database}${this.ticketData.schema ? `/${this.ticketData.schema}` : ''}/`,
        templateIdentity: this.ticketData.templateIdentity.split('|')[0],
        approvalType: this.ticketData.templateIdentity.split('|')[2],
        rawSql: this.myCodeMirror.getValue(),
        description: this.ticketData.description,
        ticketTitle: this.ticketData.ticketTitle,
        expectedExecTimeMs: this.ticketData.expectedExecTimeMs ? this.ticketData.expectedExecTimeMs : new Date().getTime(),
        immediately: this.ticketData.immediately === 'immediately',
        approTemplateName: this.ticketData.templateIdentity.split('|')[1]
      };
      const res = await this.$services.createTicket({ data });
      if (res.success) {
        if (res.data.ruleCheckResultVO.allPassed) {
          this.$router.push({ path: `/ticket/${res.data.ticketId}` });
        } else {
          this.checkedSql = res.data.ruleCheckResultVO.checkedSql;
          const h = this.$createElement;
          const rulesData = [];
          res.data.ruleCheckResultVO.notPassedRules.map((item) => {
            rulesData.push({
              ruleName: item
            });
            return null;
          });
          Modal.error({
            title: this.$t('cuo-wu-xin-xi'),
            width: 700,
            okText: this.$t('zhi-dao-le'),
            content: h('div', {
              style: {
                maxHeight: '500px',
                overflow: 'auto'
              }
            }, [
              h('p', {
                style: {
                  color: '#FF1815',
                  margin: '14px 0',
                  fontFamily: 'PingFangSC-Semibold',
                  fontWeight: 500
                }
              }, this.$t('gong-dan-sql-gui-ze-xiao-yan-shi-bai')),
              h('p', {
                style: {
                  marginBottom: '7px'
                }
              }, this.$t('bu-fu-he-gui-ze-de-sql')),
              h('p', {
                style: {
                  marginBottom: '10px'
                }
              }, this.checkedSql),
              h('a-table', {
                props: {
                  size: 'small',
                  columns: [{
                    title: this.$t('gui-ze-ming-cheng'),
                    dataIndex: 'ruleName'
                  }],
                  dataSource: rulesData
                }
              })
            ])
          });
        }
      }
      this.loading = false;
    },
    handleSubmitTicket() {
      if (!this.ticketData.ticketTitle || !this.ticketData.templateIdentity || !this.ticketData.instanceId
        || (!noStruct(this.selectedDs.dataSourceType) && !this.ticketData.database) || !this.myCodeMirror.getValue()) {
        Modal.warning({
          title: this.$t('ti-jiao-gong-dan-yi-chang'),
          content: this.$t('qing-wan-zheng-tian-xie-bi-yao-xin-xi')
        });
      } else {
        this.createTicket();
      }
    },
    async listDsSchema() {
      const res = await this.$services.listDsSchema({
        data: {
          dataSourceId: this.ticketData.instanceId,
          parentData: this.ticketData.database,
          useVisibility: true
        }
      });
      if (res.success) {
        this.ticketData.schemaList = res.data;
        this.ticketData = { ...this.ticketData };
      }
    },
    async listSchemaFirstLevel() {
      const res = await this.$services.listSchemaFirstLevel({
        data: {
          dataSourceId: this.ticketData.instanceId,
          useVisibility: true
        },
        page: 'ticket'
      });
      if (res.success) {
        this.ticketData.dbList = res.data.nameList;
        this.ticketData.hasNextLevel = res.data.hasNextLevel;
        this.ticketData = { ...this.ticketData };
      }
      this.currentMethod = 'listFirstLevel';
    },
    handleChangeInstance() {
      this.ticketData.database = null;
      this.ticketData.schema = null;
      this.allDsList.map((ds) => {
        if (ds.id === this.ticketData.instanceId) {
          this.selectedDs = ds;
          this.ticketData.dataSourceType = ds.dataSourceType;
        }
        return null;
      });

      this.getDsTemplates();
      if (noStruct(this.selectedDs.dataSourceType)) {
        return;
      }

      this.listSchemaFirstLevel();
    },
    async listDbTables() {
      let data = {};
      if (hasSchema(this.ticketData.dataSourceType)) {
        data = {
          dataSourceId: this.ticketData.instanceId,
          schemaName: this.ticketData.schema,
          parentData: this.ticketData.database,
          useVisibility: true
        };
      } else {
        data = {
          dataSourceId: this.ticketData.instanceId,
          schemaName: this.ticketData.database,
          useVisibility: true
        };
      }
      const res = await this.$services.listDbTables({
        data
      });
      if (res.success) {
        this.ticketData.tableList = res.data;
      }
    },
    handleChangeDb() {
      if (this.ticketData.hasNextLevel) {
        this.listDsSchema();
      } else {
        this.listDbTables();
      }
      this.ticketData.schema = null;
    },
    handleChangeSchema() {
      this.listDbTables();
    }
  }
};
</script>
<style lang="less">
.ticket-create-container {
  padding: 20px;
  position: relative;

  .refresh-btn {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .create-content-container {
    margin-top: 16px;
    position: relative;

    .create-ticket-content {
      width: 30%;
      position: absolute;
      right: 0;
      top: 0;
      border: 1px solid #DADADA;
      background: @background-grey;
      padding: 20px;
      height: 100%;

      .ticket-template-container {
        .ticket-from {
          display: inline-block;
        }

        .ivu-select {
          display: inline-block;
        }
      }

      .create-ticket-form-btn {
        margin-top: 20px;

        button {
          margin-right: 16px;
        }
      }
    }

    .create-ticket-editor {
      margin-right: 30%;

      .create-ticket-editor-operator {
        padding: 13px 10px;
        border: 1px solid #EAEAEA;
        border-right: none;

        .ivu-select:first-child {
          margin-right: 4px;
        }
      }

      .codemirrorHook {
        border-left: 1px solid #EAEAEA;
        border-bottom: 1px solid #EAEAEA;

        .CodeMirror {
          height: 100% !important;
          border-bottom: none !important;
        }
      }
    }
  }
}
</style>
