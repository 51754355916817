<template>
  <div ref="codemirror" class="codemirrorHook"></div>
</template>
<script>
import * as CodeMirror from './codemirror/lib/codemirror';
import './codemirror/lib/codemirror.css';
import './codemirror/lib/codemirror';
import './codemirror/mode/sql/sql';
import './codemirror/custom/custome-sql-hint';
// import './codemirror/addon/hint/show-hint';
import './codemirror/addon/hint/sql-hint';
import './codemirror/keymap/sublime';
import './codemirror/addon/hint/show-hint.css';
import '../style/cc-editor.css';
import { hasSchema } from '../../../utils/index';

export default {
  name: 'myEditor',
  props: {
    updateMyMirror: Function,
    completeAfter: Function,
    autocomplete: Function,
    tab: Object,
    storeQueryTabs: Function,
    type: String,
    onRun: Function
  },
  data() {
    return {
      myCodeMirror: ''
    };
  },
  mounted() {
    const myCodemirror = CodeMirror(this.$refs.codemirror, {
      tabSize: 4,
      lineNumbers: true,
      // gutters: ['CodeMirror-linenumbers', 'breakpoints'],
      line: true,
      indentWithTabs: true,
      smartIndent: true,
      autofocus: true,
      mode: hasSchema(this.tab.dataSourceType) ? 'text/x-pgsql' : 'text/x-generic',
      theme: 'cc-editor',
      keyMap: 'sublime',
      escape: true,
      hintOptions: {
        completeSingle: false
      },
      extraKeys: {
        'Ctrl-Enter': this.onRun
      }
    });
    this.myCodeMirror = myCodemirror;
    myCodemirror.on('inputRead', () => {
      this.valChange();
    });
    myCodemirror.on('keyHandled', this.valChange);
    myCodemirror.on('cursorActivity', this.cursorActivityHandler);
    this.updateMyMirror(myCodemirror);
    this.updateEditorValue();
  },
  methods: {
    updateEditorValue() {
      this.myCodeMirror.setOption('mode', hasSchema(this.tab.dataSourceType) ? 'text/x-pgsql' : 'text/x-generic');
      if (this.tab && this.tab.queryText) {
        this.myCodeMirror.setValue(this.tab.queryText);
      } else {
        this.myCodeMirror.setValue('');
      }
    },
    valChange(e, changes) {
      const directionKey = [
        'Cmd-A',
        'Ctrl-A',
        'Alt-Left',
        'Alt-Right',
        'Up',
        'Down',
        'Left',
        'Right'
      ];

      if (directionKey.includes(changes)) {
        return;
      }
      const doc = this.myCodeMirror.getDoc();
      const pos = doc.getCursor();

      this.newCode = this.myCodeMirror.getValue();

      this.$nextTick(() => {
        this.myCodeMirror.setCursor({
          line: pos.line,
          ch: pos.ch
        });
      });
      if (this.type === 'query') {
        this.tab.queryText = this.myCodeMirror.getValue();
        this.storeQueryTabs();
      }
    },
    cursorActivityHandler() {
      const hintSql = CodeMirror.hint.sql;
      const hintData = {};
      hintData.databases = {};
      hintData.tables = {};
      hintData.schemas = {};
      if (this.tab.dbList) {
        this.tab.dbList.map((db) => {
          hintData.databases[db.name || db] = {};
          if (db.name === this.tab.database || db === this.tab.database) {
            if (this.tab.schemaList && this.tab.schemaList.length) {
              this.tab.schemaList.map((schema) => {
                hintData.databases[this.tab.database][schema.name || schema] = {};
                if (schema.name === this.tab.schema || db === this.tab.schema) {
                  if (this.tab.tableList && this.tab.tableList instanceof Array) {
                    this.tab.tableList.map((table) => {
                      hintData.databases[this.tab.database][this.tab.schema][table.name || table.title] = {};
                      hintData.tables[table.name || table.title] = {};
                      return null;
                    });
                  }
                }
                return null;
              });
            } else if (this.tab.tableList.length && this.tab.tableList instanceof Array) {
              this.tab.tableList.map((table) => {
                hintData.databases[this.tab.database][table.name || table.title] = {};
                hintData.tables[table.name || table.title] = {};
                return null;
              });
            }
          }
          return null;
        });
      }
      if (this.tab.schemaList) {
        this.tab.schemaList.map((schema) => {
          hintData.schemas[schema.name || schema] = {};
          return null;
        });
      }
      hintData.currentDbName = this.tab.database;
      hintData.dataSourceId = this.tab.instanceId;
      hintData.instance = this.tab.instance;
      hintData.schema = this.tab.schema;
      CodeMirror.showHint(this.myCodeMirror, hintSql, hintData);
      const cm = this.myCodeMirror;
      const doc = cm.getDoc();
      // 获取光标所在位置
      const pos = doc.getCursor();
      const lines = doc.getValue()
        .split('\n');
      // cm.setGutterMarker(pos.line, 'breakpoints', this.makeMarker());
      let startLineCount = 0;
      let endLineCount = 0;
      let startCh = 0;
      let endCh = 0;
      for (let i = pos.line; i < lines.length; i++) {
        if (lines[i].indexOf(';') > -1) {
          endLineCount = i;
          endCh = lines[i].indexOf(';');
          break;
        } else {
          endLineCount = i;
          endCh = lines[i].length;
        }
      }
      for (let i = pos.line; i >= 0; i--) {
        if (lines[i].indexOf(';') > -1) {
          startLineCount = i;
          // eslint-disable-next-line no-mixed-operators
          if (startLineCount !== endLineCount || startLineCount === endLineCount && lines[i].indexOf(';') !== endCh) {
            if (startLineCount === pos.line) {
              startCh = 0;
            } else if (lines[i].indexOf(';') < lines[i].length - 1) {
              startCh = lines[i].indexOf(';');
            } else {
              startLineCount++;
              startCh = 0;
            }
            break;
          }
        }
      }
      const codeLines = document.getElementsByClassName('CodeMirror-line');
      for (let i = 0; i < codeLines.length; i++) {
        const childs = codeLines[i].childNodes;
        for (let j = 0; j < childs.length; j++) {
          const spans = childs[j].childNodes;
          for (let k = 0; k < spans.length; k++) {
            if (spans[k].classList) {
              spans[k].classList.remove('selected-span');
            }
          }
        }
      }
      if (!doc.getSelection()) {
        doc.markText({
          line: startLineCount,
          ch: startCh
        }, {
          line: endLineCount,
          ch: endCh + 1
        }, {
          className: 'selected-span'
        });
      }
    },
    makeMarker() {
      const marker = document.createElement('i');
      marker.className = 'cc-iconfont i-clouddm icon-TIP';
      marker.style.fontSize = '12px';
      marker.style.color = '#ff7977';
      return marker;
    }
  },
  watch: {
    'tab.key': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.updateEditorValue();
        }
      },
      deep: true
    }
  }
};
</script>
<style>
.selected-span {
  background: #E6F1FF;
  height: 18px;
  line-height: 18px;
  display: inline-block;
}
</style>
