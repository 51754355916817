<template>
  <div>
    <a-select style="width: 240px;margin-right:8px" v-model="ticketData.instanceId" @change="handleChangeInstance"
              :placeholder="$t('qing-xuan-ze-shu-ju-yuan-shi-li')" :filter-option="filterOption" show-search>
      <a-select-option v-for="ds in allDsList" :value="ds.id" :key="ds.id">
        <cc-data-source-icon color="#4DBAEE" :size="18" :type="ds.dataSourceType"
                             :instanceType="ds.deployEnvType"></cc-data-source-icon>
        {{ ds.instanceDesc ? ds.instanceDesc : ds.instanceId }}
      </a-select-option>
    </a-select>
    <!--    <cc-filter-select selectStyle="width: 180px;margin-right:8px" vModal="ticketData.database"-->
    <!--                      :defaultValue="ticketData.database"-->
    <!--                      :handleChange="handleChangeDb" placeholder="请选择数据库"-->
    <!--                      :dataList="ticketData.dbList"></cc-filter-select>-->
    <!--    <cc-filter-select v-if="hasSchema(selectedDs.dataSourceType)" selectStyle="width: 180px"-->
    <!--                      vModal="ticketData.schema" :defaultValue="ticketData.schema" :handleChange="handleChangeSchema"-->
    <!--                      placeholder="请选择schema" :dataList="ticketData.schemaList"></cc-filter-select>-->
    <a-select style="width: 200px;margin-right:8px" v-model="ticketData.database" @change="handleChangeDb"
              :placeholder="$t('qing-xuan-ze-shu-ju-ku')" :filter-option="filterOption" show-search v-if="!noStruct(selectedDs.dataSourceType)">
      <a-select-option v-for="db in ticketData.dbList" :value="db" :key="db">
        <cc-iconfont name="shujuku" color="#999999" :size="12"></cc-iconfont>
        {{
          db
        }}
      </a-select-option>
    </a-select>
    <v-select
      style="width: 200px;display: inline-block"
      v-if="hasSchema(selectedDs.dataSourceType)&&ticketData.schemaList"
      v-model="ticketData.schema"
      :options="paginated"
      :filterable="false"
      label="name"
      value="name"
      @open="onOpen"
      @close="onClose"
      :reduce="schema => schema.name"
      @search="(query) => (search = query)"
    >
      <template #list-footer>
        <li v-show="hasNextPage" ref="load" class="loader">
          {{ $t('zheng-zai-jia-zai-geng-duo-shu-ju') }}
        </li>
      </template>
      <template #no-options>
        {{ $t('mei-you-dui-ying-de-shu-ju') }}
      </template>
    </v-select>
  </div>
</template>
<script>
import { hasSchema, noStruct } from '../../../utils/index';

export default {
  props: {
    ds: Object,
    ticketData: Object,
    allDsList: Array,
    handleChangeInstance: Function,
    handleChangeDb: Function,
    selectedDs: Object,
    handleChangeSchema: Function
  },
  data() {
    return {
      hasSchema,
      noStruct,
      observer: new IntersectionObserver(this.infiniteScroll),
      limit: 50,
      search: ''
    };
  },
  computed: {
    filtered() {
      return this.ticketData.schemaList ? this.ticketData.schemaList.filter((country) => country.name.includes(this.search)) : [];
    },
    paginated() {
      return this.filtered.slice(0, this.limit);
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length;
    }
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[1].text.toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limit += 50;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    }
  }
};
</script>
