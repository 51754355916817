<template>
  <div>
    <div class="filter-ticket-container">
      <a-form layout="inline">
        <a-form-item :label="$t('shen-qing-ri-qi')" :label-col="{ span: 4 }" :wrapper-col="{ span: 14}">
          <a-range-picker v-model="searchKey.daterange" format="YYYY-MM-DD HH:mm"
                          :default-value="[moment(new Date(new Date().getTime() - 90 * 24 * 3600 * 1000), 'YYYY-MM-DD HH:mm'),
                           moment(new Date(), 'YYYY-MM-DD HH:mm')]"
                          style="width: 300px;margin-top: 4px"/>
        </a-form-item>
        <a-form-item :label="$t('zhuang-tai')" v-if="type!=='todo'">
          <a-select style="width:120px;margin-top: 4px" v-model="searchKey.ticketStatus">
            <a-select-option value="">
              {{ $t('quan-bu') }}
            </a-select-option>
            <a-select-option v-for="(status,key) in TICKET_STATUS" :value="key" :key="key">
              {{ status }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <!--        <FormItem label="审批流模板">-->
        <!--          <Select style="width:120px" v-model="searchKey.type">-->
        <!--            <Option v-for="template in templateList"-->
        <!--                    :value="template.templateIdentity+'|'+template.approTemplateName"-->
        <!--                    :key="template.templateIdentity">-->
        <!--              {{ template.approTemplateName }}-->
        <!--            </Option>-->
        <!--          </Select>-->
        <!--        </FormItem>-->
      </a-form>
      <div>
        <a-input :placeholder="$t('qing-shu-ru-gong-dan-biao-ti-guan-jian-zi-cha-xun')" v-model="searchKey.ticketTitleName"
                 style="width: 280px"/>
        <a-button type="primary" class="ticket-search-btn" @click="handleRefresh(searchKey)">{{ $t('cha-xun') }}
        </a-button>
      </div>
      <div class="ticket-btn">
        <a-button type="primary" ghost @click="handleCreateTicket">{{ $t('ti-jiao-gong-dan') }}</a-button>
        <a-button type="default" @click="handleRefresh(searchKey)">{{ $t('shua-xin') }}</a-button>
      </div>
    </div>
    <a-table size="small" :columns="ticketColumns" :data-source="ticketData" :row-key="row => row.id" :pagination="false">
      <template #ticketType="row">
        {{ row.approBiz === 'EXECUTE' ? $t('shu-ju-bian-geng') : $t('quan-xian-shen-qing') }}
      </template>
      <div slot="ticketStatus" slot-scope="ticketStatus">
        <div class="ticket-status" :style="`color:${TICKET_STATUS_COLOR[ticketStatus]}`">
          <div class="type" :style="styleVar(ticketStatus)"/>
          <div class="content">{{ TICKET_STATUS[ticketStatus] }}</div>
        </div>
      </div>
      <span slot="ticketTitle" slot-scope="ticketTitle">
        {{ ticketTitle.ticketTitle }}
        <!--            <a @click="handleGoDetail(ticketTitle)">{{ ticketTitle.ticketTitle }}</a>-->
      </span>
      <span slot="targetInfo" slot-scope="row">
        <span v-if="row.dataSourceInstName">
                  <cc-data-source-icon color="#4DBAEE" :size="18" :type="row.dataSourceType"
                                       :instanceType="row.deployEnvType"></cc-data-source-icon>
        {{ row.dataSourceDesc || row.dataSourceInstName }}{{ row.targetInfo }}
        </span>
        <span v-if="!row.dataSourceInstName">
          {{ $t('shi-li-yi-shan-chu') }}
        </span>
      </span>
      <span slot="action" slot-scope="action">
            <a @click="handleGoDetail(action)">{{ $t('cha-kan-xiang-qing') }}</a>
      </span>
    </a-table>
    <a-pagination v-model="pageData.pageNum" :page-size="pageData.pageSize" :total="total" show-less-items size="small" style="margin-top: 10px;float: right;" @change="handlePageChange"/>
  </div>
</template>

<script>
import moment from 'moment';
import { TICKET_STATUS, TICKET_STATUS_COLOR } from '../../../const/index';

export default {
  name: 'TicketList',
  props: {
    type: String,
    ticketData: Array,
    handleRefresh: Function,
    pageData: Object,
    total: Number
  },
  data() {
    return {
      searchKey: {
        daterange: [moment(new Date(new Date().getTime() - 90 * 24 * 3600 * 1000), 'YYYY-MM-DD HH:mm'),
          moment(new Date(), 'YYYY-MM-DD HH:mm')],
        ticketStatus: '',
        type: '',
        ticketTitleName: ''
      },
      placement: '',
      pageSize: 20,
      page: 1,
      pageSizeList: [20, 50, 100],
      moment,
      TICKET_STATUS,
      TICKET_STATUS_COLOR,
      templateList: [],
      ticketColumns: [
        {
          title: this.$t('zhuang-tai'),
          dataIndex: 'ticketStatus',
          // scopedSlots: { customRender: 'ticketStatus' },
          // slots: { title: 'ticketStatus' },
          scopedSlots: { customRender: 'ticketStatus' },
          width: 150
        },
        {
          title: this.$t('gong-dan-hao'),
          width: 150,
          dataIndex: 'bizId'
        },
        {
          title: this.$t('biao-ti'),
          minWidth: 200,
          slots: { title: 'ticketTitle' },
          scopedSlots: { customRender: 'ticketTitle' }
        },
        {
          title: this.$t('shen-pi-liu-mo-ban'),
          minWidth: 200,
          dataIndex: 'approTemplateName'
        },
        {
          title: this.$t('shi-li-ku'),
          minWidth: 300,
          slots: { title: 'targetInfo' },
          scopedSlots: { customRender: 'targetInfo' }
        },
        {
          title: this.$t('gong-dan-lei-bie'),
          minWidth: 100,
          scopedSlots: { customRender: 'ticketType' }
        },
        // {
        //   title: '期望执行时间',
        //   width: 180,
        //   key: 'expectedExecTime'
        // },
        {
          title: this.$t('shen-qing-ren'),
          width: 100,
          dataIndex: 'userName'
        }, {
          title: this.$t('ti-jiao-shi-jian'),
          width: 180,
          dataIndex: 'gmtCreate'
        }, {
          title: this.$t('jie-shu-shi-jian'),
          dataIndex: 'finishTime',
          width: 180
        },
        {
          title: this.$t('cao-zuo'),
          minWidth: 100,
          slots: { title: 'action' },
          scopedSlots: { customRender: 'action' }
        }
      ],
      showData: []
    };
  },
  mounted() {
    this.getShowData();
  },
  computed: {
    styleVar() {
      return (ticketStatus) => ({
        '--status-color': TICKET_STATUS_COLOR[ticketStatus]
      });
    }
  },
  methods: {
    handlePageChange() {
      this.handleRefresh(this.searchKey);
    },
    getShowData() {
      this.showData = this.ticketData.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
    },
    async listApproTemplates() {
      const data = {
        approvalType: this.ticketData.approvalType
      };
      const res = await this.$services.listApproTemplates({ data });
      if (res.success) {
        this.templateList = res.data;
      }
    },
    handleCreateTicket() {
      this.$router.push({ path: 'ticket_create' });
    },
    handleGoDetail(row) {
      this.$router.push({ path: `/ticket/${row.id}` });
    },
    showTotal() {
      return this.$t('gong-you-thisticketdatalength-ge-gong-dan', [this.ticketData.length]);
    },
    handleChangePage(page) {
      this.page = page;
      this.getShowData();
    },
    handleChangeSize(size) {
      this.size = size;
      this.page = 1;
      this.getShowData();
    }
  }
};
</script>
<style lang="less" scoped>
.filter-ticket-container {
  display: flex;
  position: relative;
  vertical-align: middle;
  line-height: 40px;

  .ticket-btn {
    position: absolute;
    right: 0;
    top: 0;

    .ant-btn {
      margin-left: 10px;
    }
  }

  .ticket-search-btn {
    margin-left: 10px;
  }
}

.page-container {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

.ticket-status {
  display: flex;
  align-items: center;

  .type {
    .circle(8px, #52C41A);
    background: var(--status-color);
    margin-right: 6px;
  }
}

.page-container {
  position: fixed;
  bottom: 20px;
}
</style>
