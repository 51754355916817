<template>
  <div class="ticket-detail-container">
    <a-breadcrumb>
      <a-breadcrumb-item><a href="/#/ticket">{{ $t('gong-dan') }}</a></a-breadcrumb-item>
      <a-breadcrumb-item>{{ $t('gong-dan-xiang-qing') }}</a-breadcrumb-item>
    </a-breadcrumb>
    <a-button @click="getTicketDetail('refresh')" class="refresh-btn" :loading="loading">{{ $t('shua-xin') }}
    </a-button>
    <a-card dis-hover class="ticket-detail-status">
      <div slot="title">
        <p class="ticket-title-p" style="display: flex;align-items: center;">
          <span class="ticket-title">{{ ticketDetail.ticketTitle }}</span>
          <span class="ticket-status-total">{{ TICKET_STATUS[ticketDetail.ticketStatus] }}</span>
          <a-icon type="solution" style="color: #ff6e0d;font-size: 18px;" @click="handleShowStatusMessageModal(true)" v-if="ticketDetail.statusMessage"/>
        </p>
        <div class="ticket-detail-summary">
          <div class="ticket-detail-item">
            <span class="ticket-detail-item-title">{{$t('shen-qing-ren')}}:</span>
            <span>{{ ticketDetail.userName }}</span>
          </div>
          <div class="ticket-detail-item">
            <span class="ticket-detail-item-title">{{$t('huan-jing')}}:</span>
            <span>{{ ticketDetail.dsEnvName }}</span>
          </div>
          <div class="ticket-detail-item">
            <span class="ticket-detail-item-title">{{$t('db-shi-li-ku-ming')}}:</span>
            <cc-data-source-icon color="#4DBAEE" :size="18" :type="ticketDetail.dataSourceType"
                                 :instanceType="ticketDetail.deployEnvType"></cc-data-source-icon>
            <span>{{
                ticketDetail.dataSourceDesc ? ticketDetail.dataSourceDesc : ticketDetail.dataSourceInstName
              }}{{ ticketDetail.targetInfo }}</span>
          </div>
          <div class="ticket-detail-item">
            <span class="ticket-detail-item-title">{{$t('shen-pi-liu-mo-ban')}}:</span>
            <span>{{ ticketDetail.approTemplateName }}</span>
          </div>
        </div>
        <div class="ticket-detail-operators" v-if="showOpBtns">
          <a-button class="warning-btn"
                    v-if="ticketDetail.ticketStatus===TICKET_DETAIL_STATUS.WAIT_CONFIRM && userInfo.authArr.includes('TICKET_CONFIRM') && hasPermission && !isAuthTicket"
                    ghost @click="handleConfirm('REFUSE')">
            {{ $t('ju-jue-zhi-hang') }}
          </a-button>
          <a-button type="primary"
                    v-if="ticketDetail.ticketStatus===TICKET_DETAIL_STATUS.WAIT_CONFIRM && userInfo.authArr.includes('TICKET_CONFIRM')  && hasPermission && !isAuthTicket"
                    @click="handleConfirm('CONFIRM_AND_AUTO_EXEC')">{{ $t('zi-dong-zhi-hang') }}
          </a-button>
          <a-button type="primary"
                    v-if="ticketDetail.ticketStatus===TICKET_DETAIL_STATUS.WAIT_CONFIRM && userInfo.authArr.includes('TICKET_CONFIRM')  && hasPermission && !isAuthTicket"
                    @click="handleConfirm('CONFIRM_AND_MANUAL_EXEC')">{{ $t('shou-dong-zhi-hang') }}
          </a-button>
          <a-button type="primary"
                    v-if="showCancelBtn"
                    @click="handleCancel"
                    ghost>{{ $t('che-xiao') }}
          </a-button>
          <a-button type="primary"
                    v-if="ticketDetail.ticketStatus===TICKET_DETAIL_STATUS.EXEC_FAIL && userInfo.authArr.includes('TICKET_RETRY')  && hasPermission"
                    @click="retryTicket"
                    ghost>{{ $t('zhong-shi') }}
          </a-button>
          <a-button type="warning"
                    v-if="userInfo.authArr.includes('TICKET_CLOSE')  && hasPermission && !showCancelBtn"
                    @click="closeTicket"
                    ghost>{{ $t('guan-bi-gong-dan') }}
          </a-button>
        </div>
      </div>
      <div class="ticket-detail-wrapper" v-if="ticketDetail.ticketProcessVOList">
        <div class="step-line">

        </div>
        <div class="step-item">
          <span
            :class="`step-status-point step-status-success`"></span>
          <div class="step-item-item">
            <span>{{ $t('chuang-jian-ren-wu') }}</span>
          </div>
          <div class="step-item-item">
            <span class="step-detail-label">{{$t('shen-qing-ren')}}:</span>
            <span class="step-detail-value">{{ ticketDetail.userName }}</span>
          </div>
          <div class="step-item-item">
            <span class="step-detail-label">{{$t('zhuang-tai')}}:</span>
            <span class="step-detail-value">
              <div class="ticket-status"
                   style="color:#52C41A">
                  <div class="type" style="background:#52C41A"></div>
                  <div class="content">{{ $t('yi-ti-jiao') }}</div>
              </div>
            </span>
          </div>
          <div class="step-item-item">
            <span class="step-detail-label">{{$t('shi-jian')}}:</span>
            <span class="step-detail-value">{{ ticketDetail.gmtCreate }}</span>
          </div>
          <div class="step-item-item">
            <span class="step-detail-label">{{$t('xu-qiu-miao-shu')}}:</span>
            <span class="step-detail-value">{{ ticketDetail.description }}</span>
          </div>
        </div>
        <div class="step-item" v-for="(process, index) in ticketDetail.ticketProcessVOList" :key="process.ticketProcessId">
          <div v-if="process.ticketStage === 'APPROVAL'">
            <span :class="`step-status-point ${process.finished?'step-status-success':'step-status-ing'}`" />
            <div class="step-item-item">
              <span>{{process.ticketStageTitle}}</span>
            </div>
            <div class="apply-container">
              <div class="apply-dm-level">
                <div v-if="ifExpand" class="apply-contain-wrapper"></div>
                <div class="step-item-item">
                  <cc-iconfont v-if="!ifExpand" :size=12 name="expand"
                               @click.native="handleExpand(true)"/>
                  <cc-iconfont v-if="ifExpand" :size=12 name="close"
                               @click.native="handleExpand(false)"/>
                  <span class="step-detail-label">{{$t('zhuang-tai')}}:</span>
                  <span class="step-detail-value">
                  <TicketStatus :ticketDetail="ticketDetail" :process="process"></TicketStatus>
                </span>
                </div>
                <div class="step-item-item">
                  <span class="step-detail-label">{{$t('shen-pi-wan-cheng-shi-jian')}}:</span>
                  <span class="step-detail-value">
                    {{getCurrentApproveInfo.finishTime}}
            </span>
                </div>
              </div>
              <div v-if="ifExpand && process.stageContext">
                <div
                  v-for="stage in JSON.parse(process.stageContext).approNodeDetailList"
                  :key="stage.id">
                  <div class="step-item-item">
                    <span class="step-detail-label">{{$t('jie-dian-lei-xing')}}:</span>
                    <span class="step-detail-value">
                  {{ DINGDING_STAGE_TYPE[stage.dingOpRecordType] }}
                </span>
                  </div>
                  <div class="step-item-item">
                    <span class="step-detail-label">{{$t('chu-li-ren')}}:</span>
                    <span class="step-detail-value">
                  {{stage.reviewerName }}
            </span>
                  </div>
                  <div class="step-item-item">
                    <span class="step-detail-label">{{$t('chu-li-jie-guo')}}:</span>
                    <span class="step-detail-value"
                          :style="`color:${stage.dingApproNodeOpResult === 'AGREE'?'#52C41A':stage.dingApproNodeOpResult === 'REFUSE'?'#FF1815':''}`">
                    <TicketStatus
                      :ticketDetail="ticketDetail"
                      v-if="stage.dingOpRecordType==='EXECUTE_TASK_NORMAL'&&stage.dingApproNodeOpResult==='NONE'" />
                    <span
                      v-if="!(stage.dingOpRecordType==='EXECUTE_TASK_NORMAL'&&stage.dingApproNodeOpResult==='NONE')">{{ DINGDING_STAGE_RESULT[stage.dingApproNodeOpResult] }}</span>
                </span>
                  </div>
                  <div class="step-item-item">
                    <span class="step-detail-label">{{$t('chu-li-shi-jian')}}:</span>
                    <span class="step-detail-value">
                    {{stage.finishTime }}
            </span>
                  </div>
                  <div class="step-item-item">
                    <span class="step-detail-label">{{$t('bei-zhu')}}:</span>
                    <span class="step-detail-value">{{stage.remark}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <span :class="`step-status-point ${process.finished?'step-status-success':currentStep===index ? 'step-status-ing' : ''}`" />
            <div class="step-item-item">
              <span>{{process.ticketStageTitle}}</span>
            </div>
            <div class="step-item-item">
              <span></span>
              <span class="step-detail-label">{{$t('chu-li-ren')}}:</span>
              <span class="step-detail-value">
              {{process.stageContext ? JSON.parse(process.stageContext).confirmUserName : ''}}
            </span>
            </div>
            <div class="step-item-item">
              <span class="step-detail-label">{{$t('zhuang-tai')}}:</span>
              <span class="step-detail-value">
              <TicketStatus :ticketDetail="ticketDetail" :process="process"></TicketStatus>
            </span>
            </div>
            <div class="step-item-item">
              <span class="step-detail-label">{{$t('shi-jian')}}:</span>
              <span class="step-detail-value">{{process.finishTime}}</span>
            </div>
            <div class="step-item-item">
              <span class="step-detail-label">{{$t('bei-zhu')}}:</span>
              <span class="step-detail-value">
              {{process.stageContext ? JSON.parse(process.stageContext).confirmMsg : ''}}
            </span>
            </div>
          </div>
        </div>
      </div>
    </a-card>
    <a-collapse class="ticket-content" :forceRender="true" v-model="activeKey"
                @change="handleOpenContent">
      <a-collapse-panel key="2" v-if="sqlProcessVOList.length">
        <div slot="header">
          {{ $t('sql-fen-xi') }} <span style="color: red;">（{{sqlProcessVOList.length}}{{$t('tiao')}}）</span>
        </div>
        <Table :columns="sqlColumns" :data="sqlProcessVOList" size="small">
          <template slot-scope="{ row }" slot="sql">
            {{row.parsedSql}}
          </template>
          <template #message="{ row }">
            {{row.explainInfo.message}}
          </template>
        </Table>
      </a-collapse-panel>
      <a-collapse-panel key="1" :header="$t('gong-dan-nei-rong')">
        <div ref="codemirror" class="codemirrorHook"></div>
        <div v-if="isAuthTicket">
          <all-auth :auth-data="ticketDetail.requestAuthData"/>
        </div>
      </a-collapse-panel>
    </a-collapse>
    <a-card class="ticket-content" dis-hover v-if="taskList.length">
      <p slot="title">{{ $t('zhi-hang-ren-wu-lie-biao') }}</p>
      <async-job-detail type="ticket" :export-job-id="taskList[0].id" ref="asyncJobDetailRef"/>
    </a-card>
    <a-modal
      :visible="showCancelTicket"
      :title="$t('che-xiao-gong-dan-que-ren')"
    >
      <p>{{ $t('gong-dan-che-xiao-hou-bu-ke-hui-fu-que-ren-yao-che-xiao-gai-gong-dan-ma') }}</p>
      <div class="footer">
        <a-button type="primary" @click="cancelTicket">{{ $t('que-ding') }}</a-button>
        <a-button @click="handleConfirmCancel">{{ $t('qu-xiao') }}</a-button>
      </div>
    </a-modal>
    <a-modal>

    </a-modal>
    <a-modal
      v-if="showStatusMessageModal"
      :visible="showStatusMessageModal"
      :title="$t('gong-dan-zhuang-tai')"
      footer-hide
      @cancel="handleShowStatusMessageModal(false)"
    >
      <div style="max-height: 500px;overflow-y: scroll">
        {{ticketDetail.statusMessage || $t('wu-0')}}
      </div>
      <div class="footer">
        <a-button type="primary" @click="handleShowStatusMessageModal(false)">{{ $t('guan-bi') }}</a-button>
      </div>
    </a-modal>
    <async-run-modal
      :visible="showConfirmModal"
      v-if="showConfirmModal"
      :on-run="confirmTicket"
      :handle-close-modal="handleConfirmCancel"
      :title="$t('li-you')"
      type="ticket"
      :data-source-type="ticketDetail.dataSourceType"
      :confirm-type="confirmType"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TicketStatus from '@/views/ticket/components/TicketStatus';
import explainDetail from '@/views/ticket/components/ExplainDetail';
import AsyncRunModal from '@/components/modal/AsyncRunModal';
import AllAuth from '@/views/system/subaccount/components/AllAuth';
import * as CodeMirror from '../sql/components/codemirror/lib/codemirror';
// eslint-disable-next-line import/no-unresolved
import '../sql/components/codemirror/addon/hint/show-hint.css';
// eslint-disable-next-line import/no-unresolved
import '../sql/style/cc-editor.css';
// eslint-disable-next-line import/no-unresolved
import '../sql/components/codemirror/lib/codemirror.css';
// eslint-disable-next-line import/no-unresolved,import/extensions
import '../sql/components/codemirror/lib/codemirror';
// eslint-disable-next-line import/no-unresolved,import/extensions
import '../sql/components/codemirror/mode/sql/sql';
// eslint-disable-next-line import/no-unresolved,import/extensions
import '../sql/components/codemirror/custom/custome-sql-hint';
// import '../sql/components/addon/hint/show-hint';
// eslint-disable-next-line import/no-unresolved,import/extensions
import '../sql/components/codemirror/addon/hint/sql-hint';
// eslint-disable-next-line import/no-unresolved,import/extensions
import '../sql/components/codemirror/keymap/sublime';
import {
  TICKET_STATUS,
  DINGDING_STAGE_TYPE,
  DINGDING_STAGE_RESULT,
  TICKET_STATUS_COLOR,
  BIZ_TYPE,
  APPROVAL_BIZ_TYPE, TICKET_DETAIL_STATUS
} from '../../const/index';
import AsyncJobDetail from '../system/AsyncJobList/asyncJobDetail';

export default {
  name: 'TicketDetail',
  components: {
    AllAuth,
    AsyncRunModal,
    AsyncJobDetail,
    TicketStatus,
    // eslint-disable-next-line vue/no-unused-components
    explainDetail
  },
  data() {
    return {
      TICKET_DETAIL_STATUS,
      taskList: [],
      startId: 0,
      pageSize: 10,
      exportJobList: [],
      preStartIds: [],
      activeKey: ['1'],
      role: 'applyer',
      ticketId: 0,
      ticketDetail: {},
      TICKET_STATUS,
      DINGDING_STAGE_TYPE,
      DINGDING_STAGE_RESULT,
      TICKET_STATUS_COLOR,
      loading: false,
      showConfirmModal: false,
      confirmType: '',
      confirmInfo: '',
      ifExpand: true,
      showCancelTicket: false,
      currentStep: 0,
      currentStepStage: '',
      myCodeMirror: null,
      hasPermission: false,
      stepList: [
        {
          id: 1,
          applyer: 'pudding',
          status: 'SUCCESS',
          applyTime: '2021-01-07 11:00:00',
          desc: this.$t('ce-shi-ce-shi')
        },
        {
          id: 2,
          applyer: 'pudding',
          status: 'ERROR',
          applyTime: '2021-01-07 11:00:00',
          desc: this.$t('ce-shi-ce-shi')
        }
      ],
      execColumns: [
        {
          title: this.$t('xu-hao'),
          key: 'seqIndex',
          dataIndex: 'seqIndex',
          width: 60
        },
        {
          title: this.$t('zhi-hang-shi-jian'),
          dataIndex: 'gmtCreate',
          key: 'gmtCreate',
          width: 180
        },
        {
          title: this.$t('jie-guo'),
          width: 80,
          slots: { title: 'result' },
          scopedSlots: { customRender: 'result' }
        },
        {
          title: this.$t('zhi-hang-sql'),
          dataIndex: 'parsedSql',
          key: 'parsedSql'
        },
        {
          title: this.$t('zhi-hang-xin-xi'),
          slots: { title: 'message' },
          scopedSlots: { customRender: 'message' }
        },
        {
          title: this.$t('zhi-hang-hao-shi-ms'),
          width: 120,
          slots: { title: 'executeTime' },
          scopedSlots: { customRender: 'executeTime' }
        }
      ],
      sqlProcessVOList: [],
      sqlColumns: [
        {
          type: 'expand',
          width: 50,
          render: (h, params) => h(explainDetail, {
            props: {
              row: params.row
            }
          })
        },
        {
          title: this.$t('sql-yu-ju'),
          slot: 'sql'
        },
        {
          title: this.$t('ke-neng-han-you-de-wen-ti'),
          slot: 'message'
        }
      ],
      showStatusMessageModal: false
    };
  },
  mounted() {
    this.ticketId = this.$route.params.id;
    this.getTicketDetail('init');
    this.checkTicketPermission();
  },
  computed: {
    showOpBtns() {
      return [
        TICKET_DETAIL_STATUS.WAIT_APPROVAL,
        TICKET_DETAIL_STATUS.WAIT_CONFIRM,
        TICKET_DETAIL_STATUS.WAIT_EXEC,
        TICKET_DETAIL_STATUS.RUNNING,
        TICKET_DETAIL_STATUS.EXEC_FAIL
      ].includes(this.ticketDetail.ticketStatus);
    },
    showCancelBtn() {
      return [TICKET_DETAIL_STATUS.WAIT_APPROVAL, TICKET_DETAIL_STATUS.WAIT_CONFIRM].includes(this.ticketDetail.ticketStatus) && this.role === 'owner';
    },
    isAuthTicket() {
      return this.ticketDetail.approBiz === APPROVAL_BIZ_TYPE.AUTH;
    },
    ...mapState({
      userInfo: (state) => state.userInfo
    }),
    getCurrentApproveInfo() {
      if (this.ticketDetail.ticketProcessVOList.length) {
        let currentInfo;
        this.ticketDetail.ticketProcessVOList.forEach((process) => {
          if (process.ticketStage === 'APPROVAL') {
            currentInfo = JSON.parse(process.stageContext);
          }
        });
        if (currentInfo && currentInfo.approNodeDetailList) {
          return currentInfo.approNodeDetailList[currentInfo.approNodeDetailList.length - 1];
        }
      }
      return '';
    }
  },
  methods: {
    handleShowStatusMessageModal(visible) {
      this.showStatusMessageModal = visible;
    },
    async getTicketDetail(type) {
      this.loading = true;
      const data = {
        ticketId: this.ticketId
      };
      const res = await this.$services.queryTicketsDetail({ data });
      this.loading = false;
      if (res.success) {
        this.ticketDetail = res.data;
        const sqlProcessVOList = this.ticketDetail.sqlProcessVOList.filter((sql) => sql.explainInfo);
        sqlProcessVOList.forEach((sql) => {
          const { explainInfo } = sql;
          if (explainInfo.rowData) {
            const rowData = JSON.parse(explainInfo.rowData);
            const columns = [];
            const tableData = [];
            rowData.columnList.forEach((column) => {
              columns.push({
                title: column,
                key: column
              });
            });
            rowData.resultSet.forEach((result) => {
              const columnsData = {};
              result.forEach((v, index) => {
                columnsData[columns[index].key] = v.value;
              });
              tableData.push(columnsData);
            });
            explainInfo.columns = columns;
            explainInfo.tableData = tableData;
          }
        });
        this.sqlProcessVOList = sqlProcessVOList;

        for (let i = 0; i < this.ticketDetail.ticketProcessVOList.length; i++) {
          const item = this.ticketDetail.ticketProcessVOList[i];
          this.currentStep = i;
          this.currentStepStage = item.ticketStage;
          if (!item.finished) {
            break;
          }
        }

        if (this.ticketDetail.userName === this.userInfo.username) {
          this.role = 'owner';
        }
        if (this.ticketDetail.approBiz === APPROVAL_BIZ_TYPE.EXECUTE) {
          if (this.$refs.codemirror.childNodes.length === 0) {
            const myCodemirror = CodeMirror(this.$refs.codemirror, {
              tabSize: 4,
              lineNumbers: true,
              line: true,
              indentWithTabs: true,
              smartIndent: true,
              autofocus: false,
              mode: 'text/x-generic',
              theme: 'cc-editor',
              keyMap: 'sublime',
              escape: true,
              readOnly: true,
              extraKeys: {
                'Ctrl-Enter': this.onRun
              }
            });
            this.myCodeMirror = myCodemirror;
          }
          this.myCodeMirror.setValue(this.ticketDetail.rawSql);
        }

        if (type === 'init') {
          this.activeKey = [];
        }

        const taskRes = await this.$services.listExportJoBbyBiz({
          data: {
            startId: this.startId,
            pageSize: this.pageSize,
            bizType: BIZ_TYPE.TICKETS_WORKFLOW,
            exportBizId: res.data.id
          }
        });
        if (taskRes.data.length) {
          this.taskList = taskRes.data;
          if (type === 'refresh') {
            await this.$refs.asyncJobDetailRef.getJobDetail();
          }
        }
      }
    },
    async checkTicketPermission() {
      const res = await this.$services.checkPermissions({ data: { ticketId: this.ticketId } });
      if (res.success) {
        this.hasPermission = res.data;
      }
    },
    async cancelTicket() {
      const data = {
        ticketId: this.ticketId,
        approvalType: this.ticketDetail.approType,
        approIdentity: this.ticketDetail.approIdentity
      };
      const res = await this.$services.cancelTicket({ data });
      if (res.success) {
        this.$Message.success(this.$t('che-xiao-cheng-gong'));
        this.showCancelTicket = false;
        this.getTicketDetail();
      }
    },
    handleCancel() {
      this.showCancelTicket = true;
    },
    async confirmTicket(asyncForm) {
      const { confirmInfo, ddl, noneDdl } = asyncForm;
      const data = {
        ticketId: this.ticketId,
        confirmActionType: this.confirmType,
        remark: confirmInfo,
        ddlSqlExecType: ddl,
        noneDdlSqlExecType: noneDdl
      };
      const res = await this.$services.confirmTicket({ data });
      if (res.success) {
        this.$Message.success(this.$t('cao-zuo-cheng-gong'));
        this.getTicketDetail();
      }
      this.showConfirmModal = false;
    },
    handleConfirm(type) {
      this.showConfirmModal = true;
      this.confirmType = type;
    },
    handleExpand(ifExpand) {
      this.ifExpand = ifExpand;
    },
    async retryTicket() {
      const data = {
        ticketId: this.ticketId
      };
      const res = await this.$services.retryTicket({ data });
      if (res.success) {
        this.$Message.success(this.$t('zhong-shi-cheng-gong'));
        this.getTicketDetail();
      }
    },
    async closeTicket() {
      const data = {
        ticketId: this.ticketId
      };
      const res = await this.$services.closeTicket({ data });
      if (res.success) {
        this.$Message.success(this.$t('guan-bi-cheng-gong'));
        this.getTicketDetail();
      }
    },
    handleConfirmCancel() {
      this.showConfirmModal = false;
      this.showCancelTicket = false;
    },
    handleOpenContent() {
      if (this.ticketDetail.approvalBiz === APPROVAL_BIZ_TYPE.EXECUTE) {
        this.myCodeMirror.setValue(this.ticketDetail.rawSql);
      }
    }
  },
  watch: {
    'userInfo.username': {
      handler(newVal) {
        console.log(newVal);
        if (this.ticketDetail.userName === newVal) {
          this.role = 'owner';
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="less">
.ticket-detail-container {
  padding: 20px;
  position: relative;

  .refresh-btn {
    position: absolute;
    right: 20px;
    top: 12px;
  }

  .ticket-detail-status {
    margin-top: 16px;
  }

  .ticket-title-p {
    line-height: 20px;
    margin-bottom: 12px;
  }

  .ivu-card-head p, .ivu-card-head-inner {
    overflow: visible;
  }

  .ticket-title {
    font-size: 14px;
    font-family: PingFangSC-Semibold;
    font-weight: 500;
  }

  .ticket-status-total {
    display: inline-block;
    border: 1px solid #F8D090;
    background: #FFF8EC;
    border-radius: 10px;
    color: #FFA30E;
    font-size: 12px;
    padding: 2px 8px;
    margin-left: 8px;
    margin-right: 5px;
  }

  .ticket-detail-summary {
    font-size: 12px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    padding-right: 200px;

    .ticket-detail-item {
      margin-right: 80px;
      color: @font-color;
      display: inline-block;

      .ticket-detail-item-title {
        color: @icon-color;
      }
    }
  }

  .ticket-detail-operators {
    position: absolute;
    right: 14px;
    top: 10px;
    display: flex;

    button {
      margin-left: 10px;
    }
  }

  .ticket-content {
    margin-top: 20px;
  }

  .ticket-detail-wrapper {
    position: relative;
    color: @font-color;

    .step-line {
      width: 2px;
      height: calc(100% - 18px);
      background-color: #E8E8E8;
      position: absolute;
      left: 6px;
      top: 12px;
      z-index: 0;
    }

    .step-item {
      margin-bottom: 20px;
      //display: flex;
      padding-right: 200px;
      //justify-content: space-between;

      .apply-container {
        display: inline-block;
        width: 72%;
        position: relative;
        vertical-align: top;
        line-height: 30px;

        .step-item-item {
          width: 20%;
          vertical-align: top;
        }

        .apply-contain-wrapper {
          width: 8px;
          height: calc(100% - 36px);
          position: absolute;
          left: -12px;
          top: 20px;
          border-left: 1px solid #C7C7C7;
          border-bottom: 1px solid #C7C7C7;
        }

        .apply-dm-level {
          .step-item-item {
            width: 25%;
            vertical-align: top;
          }
        }

        .icon-close {
          color: #C7C7C7;
          position: absolute;
          left: -20px;
          cursor: pointer;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      .step-item-item {
        //flex-grow: 1;
        //width: 300px;
        display: inline-block;
        width: 18%;
        position: relative;

        .icon-expand {
          color: #C7C7C7;
          position: absolute;
          left: -20px;
          cursor: pointer;
        }
      }

    }

    .step-status-point {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 1px solid #CCCCCC;
      background-color: #CCCCCC;
      margin-right: 8px;
      z-index: 1;
      position: relative;
    }

    .step-status-success {
      background-color: #ffffff;
      border-color: #0BB9F8;
    }

    .step-status-ing {
      background-color: #0BB9F8;
      border-color: #0BB9F8;
    }

    .step-status-error {
      background-color: #FF1815;
    }

    .step-detail-label {
      color: @icon-color;
    }

    .step-detail-value {
      display: inline-block;
    }
  }

  .ticket-status {
    display: flex;
    align-items: center;

    .type {
      .circle(8px, #52C41A);
      background: var(--status-color);
      background: var(--status-color);
      margin-right: 6px;
    }
  }

  .cm-s-cc-editor .CodeMirror-gutters {
    background: none !important;
  }
}

.modal-footer {
  margin-top: 20px;
  text-align: center;
}
</style>
